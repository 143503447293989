<template>
  <div class="episode-container" @click.prevent="episodeClickHandler(item)">
    <div class="cols">
      <div class="cell--1-3">
        <div class="cols flex--main-end">
          <div class="flex-cell--static pad-h--1 text-right">
            <a class="inline episode-link" :href="getEpisodeUrl(item)" @click.stop.prevent="episodeClickHandler(item)"> #{{ item.id }} </a>
            <div class="mar-top--1">{{ item.created_date | parseDate | formatDate }}</div>
            <div>
              <span> {{ item.created_date | parseDate | formatTimeHHMM }} - </span>
              <span v-if="!item.open" class="episode-text">
                {{ (item.closed_date || item.created_date) | parseDate | formatTimeHHMM }}
              </span>
              <span v-else class="episode-text--live">
                {{ $tf('live') | upperCase }}
              </span>
            </div>
            <div class="episode-text text-tetriary text-s" :class="{ 'episode-text--highlight': animation.faceHighlight }">
              {{ $tf('events') }} {{ item.events_count }}
            </div>
          </div>
          <div class="episode-image-container">
            <div v-if="events && events[0]">
              <img
                :key="'event-' + i"
                :src="events[0].thumbnail"
                class="episode-image-thumbnail"
                :class="{ 'episode-image-thumbnail--highlight': animation.faceHighlight }"
                v-for="i in eventsFakeArray"
                :style="getStyle(i)"
                @click.stop="(v) => showImage(events[0].fullframe, getBox(events[0]))"
              />
            </div>
          </div>
        </div>
        <div class="text-right mar-top--1" v-if="!item.acknowledged">
          <event-reaction :model="'episodes'" :item="item"></event-reaction>
        </div>
      </div>
      <div class="flex-cell--static" style="width: 1rem"></div>
      <div class="cell--1-3">
        <div class="cols height-100 flex--main-start" v-if="!loading">
          <div class="episode-image-container flex-cell--static" v-if="dossier_face">
            <img :src="dossier_face.thumbnail" class="episode-image-thumbnail" @click.stop="(v) => showImage(dossier_face.source_photo, null)" />
          </div>
          <div class="mar-h-1">
            <a v-if="dossier" :href="getDossierUrl(dossier)" class="text-xl episode-link" @click.stop.prevent="dossierClickHandler(dossier)">{{
              dossier.name
            }}</a>
            <div class="episode-text--large" v-else>{{ $tf('no_matches') }}</div>

            <div class="dossier-lists-container" v-if="item.matched_lists">
              <span
                class="dossier-list-item text-s"
                :style="{ backgroundColor: '#' + list.color, color: getFontColor(list) }"
                v-for="list in getDossierLists(item.matched_lists)"
              >
                {{ list.name | shortString }}
              </span>
            </div>

            <template v-if="showCameraMetadata">
              <episode-camera-groups class="item__camera-groups" :camera-groups="item.camera_groups" />
              <episode-cameras :cameras="item.cameras" v-if="item.cameras.length" />
            </template>

            <div v-if="$store.getters.features.temperature" class="text-s mar-top--0-5">
              {{ $tf('temperature') }}: {{ item.temperature ? item.temperature : '-' }}
            </div>
          </div>
        </div>
      </div>
      <div class="cell--1-3">
        <div class="cols height-100 flex--main-start" v-if="!loading">
          <template>
            <features :features="item.features" :header="false" v-if="!featuresDisplay" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from '@/apps/common/lodash';
import EventReaction from '@/components/events/reaction';
import Color from 'color';
import qs from 'qs';
import EpisodeCameraGroups from './episode-camera-groups.vue';
import EpisodeCameras from './episode-cameras.vue';
import Features from '../common/features.vue';
import ActionNames from '@/store/action.names';

const Item = {
  Height: 168,
  AcknowledgedHeight: 120
};

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: { EventReaction, EpisodeCameras, EpisodeCameraGroups, Features },
  props: {
    item: Object,
    showCameraMetadata: { type: Boolean, default: true }
  },
  data: function () {
    return {
      matched: null,
      dossier: null,
      dossier_face: null,
      loading: false,
      animation: {
        height: Item.AcknowledgedHeight,
        faceHighlight: false
      }
    };
  },
  computed: {
    eventsFakeArray() {
      const count = Math.min(this.item.events_count || 1, 3);
      return new Array(count).fill(0).map((v, k) => k);
    },
    state() {
      return this.$store.state.episodes;
    },
    featuresDisplay() {
      return _.isEmpty(this.item.features);
    },
    events: {
      get() {
        return this.$store.state.episodes.eventsByEpisodeId[this.item.id];
      },
      set(v) {
        this.$store.state.episodes.eventsByEpisodeId[this.item.id] = v;
      }
    },
    date() {
      return new Date(this.item.created_date).toISOString();
    },
    fixedFeatures() {
      return this.fixFeatureName(Object.entries(this.item.features));
    }
  },
  watch: {
    'item.matched_event': function (v, p) {
      this.loadDossier();
    },
    'item.events_count': function () {
      this.animation.faceHighlight = true;
      setTimeout(() => {
        this.animation.faceHighlight = false;
      }, 300);
    },
    'item.acknowledged': function (v, p) {
      this.animation.height = v ? Item.AcknowledgedHeight : Item.Height;
    }
  },
  created() {
    this.$set(this.$store.state.episodes.eventsByEpisodeId, this.item.id, null);
    this.loading = true;

    Promise.all([this.loadEvents(), this.loadDossier()]).finally(() => {
      this.loading = false;
    });

    this.playEnterAnimation();
  },
  methods: {
    playEnterAnimation() {
      if (this.item.source === 'ws') {
        this.animation.height = 0;
        setTimeout(() => {
          this.animation.height = this.item.acknowledged ? Item.AcknowledgedHeight : Item.Height;
        }, 100);
      } else {
        this.animation.height = this.item.acknowledged ? Item.AcknowledgedHeight : Item.Height;
      }
    },
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();

      return isLight ? '#000' : 'rgba(255, 255, 255, 0.8)';
    },
    getDossierLists(v) {
      const dossierLists = this.$store.state.dossier_lists.items || [];
      return v.map((id) => dossierLists.find((v) => v.id === id)).filter((v) => !!v);
    },
    getEventPromise(value) {
      return typeof value === 'string' ? this.$store.dispatch(ActionNames.FacesEvents.Get, { id: value }) : Promise.resolve(value);
    },
    loadEvents() {
      const event = this.item.open ? this.item.last_event : this.item.best_event;
      if (event && typeof event === 'object') {
        this.events = [event];
      }

      if (this.events && this.events.length) {
        return Promise.resolve(null);
      } else {
        return this.$store.dispatch(ActionNames.FacesEvents.Get, { filter: { episode: this.item.id, limit: 1 } }).then((v) => {
          this.events = v.results;
        });
      }
    },
    loadDossier() {
      if (!this.item.matched_event) return Promise.resolve(null);

      return this.getEventPromise(this.item.matched_event)
        .then((v) => {
          this.matched = v;
          return Promise.all([
            this.$store.dispatch('get_dossiers', { id: v.matched_dossier }),
            this.$store.dispatch('getBatchObjectFaces', { id_in: v.matched_object })
          ]);
        })
        .then(([dossier, dossier_face]) => {
          this.dossier = dossier;
          this.dossier_face = dossier_face;
        });
    },
    getStyle(i) {
      const padding = 10,
        imageSize = 120 - padding * i,
        opacity = Math.max(1 - i * 0.3, 0.1),
        count = Math.min(this.item.events_count, 3);

      return {
        opacity,
        right: i * padding * 0.5 + 'px',
        height: imageSize + 'px',
        width: imageSize + 'px',
        top: i * padding * 1.5 + 'px',
        'z-index': count - i
      };
    },
    getEpisodeUrl(v) {
      let filter = Object.assign({}, this.$store.state.faces_events.filter.empty, { episode: v.id });
      return '#/events/filter/' + qs.stringify(filter, { sort: alphabeticalSort });
    },
    getDossierUrl(v) {
      return '#/dossiers/' + v.id;
    },
    episodeClickHandler(v) {
      let filter = _.pickBy(Object.assign({}, this.$store.state.faces_events.filter.empty, { episode: v.id }), (v) => !!v);
      this.$router.push({ path: '/events/faces/filter/' + qs.stringify(filter, { sort: alphabeticalSort }) });
    },
    dossierClickHandler(v) {
      this.$router.push({ path: '/dossiers/' + v.id });
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(event) {
      return {
        x: event.frame_coords_left,
        y: event.frame_coords_top,
        w: event.frame_coords_right - event.frame_coords_left,
        h: event.frame_coords_bottom - event.frame_coords_top
      };
    }
  }
};
</script>

<style lang="stylus">
.item__camera-groups {
  margin: 0.5rem 0 0.25rem 0;
}
</style>
